import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';

import type { PayDebtConfirmation as Props } from './pay-debt-confirmation.types';

const PayDebtConfirmation: React.FunctionComponent<Props> = ({ layout }) => (
  <FlowTerminusLayout {...layout}>
    <div className="pay-debt-confirmation"></div>
  </FlowTerminusLayout>
);

export default PayDebtConfirmation;

import React from 'react';

import Debt from 'components/debt/debt';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import type { PayDebt as Props } from './pay-debt.types';
import ContentContainer from 'client/components/content-container/content-container';

const PayDebt: React.FunctionComponent<Props> = ({ debt, layout }) => (
  <MyPageLayout {...layout}>
    <ContentContainer>
      <div className="pay-debt">{debt ? <Debt {...debt} /> : null}</div>
    </ContentContainer>
  </MyPageLayout>
);

export default PayDebt;

/* eslint-disable react/no-multi-comp */
import LinkExternal from '@sats-group/icons/18/link-external';
import CreditCard from '@sats-group/icons/32/creditcard';
import Invoice from '@sats-group/icons/32/invoice';
import cn from 'classnames';
import React, { useState } from 'react';
import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import { useIsMounted } from 'sats-ui-lib/react/hooks/use-is-mounted';
import LinkButton from 'sats-ui-lib/react/link-button';
import Radio from 'sats-ui-lib/react/radio';
import Text from 'sats-ui-lib/react/text';

import Payment from 'components/payment/payment';
import PtShopLayout from 'components/pt-shop-layout/pt-shop-layout';
import SelfServiceSummary from 'components/self-service-summary/self-service-summary';
import type { PtShopPay as Props } from './pt-shop-pay.types';

const Placeholder: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, ...attributes }) => (
  <div {...attributes} className="pt-shop-pay__placeholder">
    {children}
  </div>
);

const TermsSkeleton: React.FC = () => (
  <div className="pt-shop-pay__terms pt-shop-pay__terms--skeleton">
    <Placeholder style={{ height: '22px', width: '36%' }} />
    <Placeholder style={{ height: '46px', width: '14%' }} />
  </div>
);

const SelectionSkeleton: React.FC = () => (
  <div className="pt-shop-pay__selection pt-shop-pay__selection--skeleton">
    <div className="pt-shop-pay__option pt-shop-pay__option--skeleton">
      <Placeholder style={{ height: '32px', width: '32px' }} />
      <Placeholder style={{ height: '22px', width: '37%' }} />
      <Placeholder style={{ height: '14px', width: '37%' }} />
    </div>
    <div className="pt-shop-pay__option pt-shop-pay__option--skeleton">
      <Placeholder style={{ height: '32px', width: '32px' }} />
      <Placeholder style={{ height: '22px', width: '37%' }} />
      <Placeholder style={{ height: '14px', width: '37%' }} />
    </div>
  </div>
);

const PrimaryElement: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="pt-shop-pay__primary">{children}</div>
);

const Primary: React.FC<Props['layout']['primary']> = ({ copy, ok }) => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const handleTerms: React.ChangeEventHandler<HTMLInputElement> = event =>
    setHasAccepted(event.target.checked);
  const [method, setMethod] = useState<'card' | 'split'>();
  const selectCard = () => setMethod('card');
  const selectSplit = () => setMethod('split');

  const isMounted = useIsMounted();
  if (!isMounted) {
    return (
      <PrimaryElement>
        <TermsSkeleton />
        <Text
          elementName="h2"
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
        >
          {copy.payment.title}
        </Text>
        <SelectionSkeleton />
      </PrimaryElement>
    );
  }

  if (!ok) {
    return <PrimaryElement>[ERROR UI]</PrimaryElement>;
  }

  const handlePaymentComplete = () =>
    window.location.assign(ok.payment.card.completeUrl);
  const handlePaymentError = () =>
    window.location.assign(ok.payment.card.errorUrl);

  return (
    <PrimaryElement>
      <div className="pt-shop-pay__terms">
        <div>
          <Checkbox {...ok.terms.field} onChange={handleTerms} />
        </div>
        <div>
          <LinkButton
            {...ok.terms.link}
            icon={<LinkExternal />}
            rel="noopener noreferrer"
            target="_blank"
            variant={LinkButton.variants.secondary}
          />
        </div>
      </div>
      <div>
        <Text
          elementName="h2"
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
        >
          {copy.payment.title}
        </Text>
      </div>

      {hasAccepted ? (
        <React.Fragment>
          <div className="pt-shop-pay__selection">
            <button
              className={cn('pt-shop-pay__option', {
                'pt-shop-pay__option--active': method === 'card',
              })}
              data-testid="card"
              onClick={selectCard}
            >
              <div>
                <CreditCard />
              </div>
              <div>
                <Text elementName="div" theme={Text.themes.emphasis}>
                  {copy.card.action}
                </Text>
                <Text elementName="div" size={Text.sizes.small}>
                  {copy.card.actionDescription}
                </Text>
              </div>
            </button>
            {ok.payment.split.options.length ? (
              <button
                className={cn('pt-shop-pay__option', {
                  'pt-shop-pay__option--active': method === 'split',
                })}
                data-testid="split"
                onClick={selectSplit}
              >
                <div>
                  <Invoice />
                </div>
                <div>
                  <Text elementName="div" theme={Text.themes.emphasis}>
                    {copy.split.action}
                  </Text>
                  <Text elementName="div" size={Text.sizes.small}>
                    {copy.split.actionDescription}
                  </Text>
                </div>
              </button>
            ) : null}
          </div>
          {method === 'card' ? (
            <div className="pt-shop-pay__card">
              <Payment
                {...ok.payment.card.payment}
                onPaymentComplete={handlePaymentComplete}
                onPaymentError={handlePaymentError}
              />
            </div>
          ) : null}
          {method === 'split' ? (
            <form
              action={ok.payment.split.endpoint}
              className="pt-shop-pay__split"
              method="post"
            >
              <Text elementName="p">{copy.split.help}</Text>
              <HiddenInput
                name={ok.terms.field.name}
                value={String(hasAccepted)}
              />
              {ok.payment.split.data.map(entry => (
                <HiddenInput {...entry} key={entry.name} />
              ))}
              {ok.payment.split.options.length ? (
                <div className="pt-shop-pay__installments">
                  <Text elementName="h3" theme={Text.themes.emphasis}>
                    {copy.split.options}
                  </Text>
                  {ok.payment.split.options.map(entry => (
                    <div className="pt-shop-pay__installment" key={entry.name}>
                      <Radio {...entry} />
                    </div>
                  ))}
                </div>
              ) : null}
              <div>
                <Button
                  text={copy.split.submit}
                  type="submit"
                  variant={Button.variants.complete}
                  wide
                />
              </div>
            </form>
          ) : null}
        </React.Fragment>
      ) : (
        <SelectionSkeleton />
      )}
    </PrimaryElement>
  );
};

const PtShopPay: React.FC<Props> = ({ layout }) => (
  <PtShopLayout
    {...layout}
    flipped
    primaryRenderer={Primary}
    secondaryRenderer={SelfServiceSummary}
  />
);

export default PtShopPay;

import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';

import type { PayDebtError as Props } from './pay-debt-error.types';

const PayDebtError: React.FunctionComponent<Props> = ({ layout }) => (
  <FlowTerminusLayout {...layout}>
    <div className="pay-debt-error"></div>
  </FlowTerminusLayout>
);

export default PayDebtError;

import React from 'react';

import Link from 'sats-ui-lib/react/link';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import Price from '../price/price';
import type { Debt as Props } from './debt.types';

const Debt: React.FunctionComponent<Props> = ({
  action,
  amount,
  disclaimer,
  document,
  id,
  name,
}) => (
  <div className="debt" data-id={id}>
    <div className="debt__panel debt__panel--primary">
      <div className="debt__header">
        <Text>{name}</Text>
        <Price {...amount} small />
      </div>
      <div>
        <Link {...document} rel="noopener noreferrer" target="_blank" />
      </div>
    </div>
    <div className="debt__panel debt__panel--secondary">
      <div>
        <Text>{disclaimer}</Text>
      </div>
      {action ? (
        <div>
          <LinkButton {...action} />
        </div>
      ) : null}
    </div>
  </div>
);

export default Debt;
